










































































import { Component, Vue } from "vue-property-decorator";
import { hxwUpdateProfile} from "@/api";

@Component
export default class UserInfo extends Vue {
  userInfo = {
    sex: 2,
    birthday: "2020-01-02",
    companyType: 1,
    nickName:"",
    email:"",
    phonenumber:"",
  };
  fullscreenLoading = false;
  hxwUpdateProfile():void{
    this.fullscreenLoading = true;
    let data = {
      birthday : this.userInfo.birthday,
      sex : this.userInfo.sex,
      nickName : this.userInfo.nickName
    };
     hxwUpdateProfile(data).then(res => {
      this.fullscreenLoading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
            this.userInfo = res.data.data
            window.localStorage.setItem("userInfo",JSON.stringify(res.data.data));
            window.location.reload();
            
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  }
  created(){
    if(window.localStorage.getItem("userInfo")){
      
      this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      
    }
  };
}
